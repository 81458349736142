import "./style.css";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as dat from "dat.gui";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { gsap, Expo, Elastic, Power2, Linear } from "gsap";
import "./js/scrollTrigger.min.js";
import $ from "jquery";
import ScrollMagic from "scrollmagic";
import { Howl, Howler } from "howler";

import elementFragment from "./glsl/elementFragment.glsl";
import elementFragment2 from "./glsl/elementFragment2.glsl";
import elementVertex from "./glsl/elementVertex.glsl";

import videoVertex from "./glsl/videoVertex.glsl";
import videoFragment from "./glsl/videoFragment2.glsl";

import buttonVertex from "./glsl/buttonVertex.glsl";
import buttonFragment from "./glsl/buttonFragment.glsl";

import Stats from "stats.js";
import { ScrollTrigger } from "gsap/all";
import { TorusKnotBufferGeometry } from "three";

import * as Hammer from "hammerjs";

//var stats = new Stats();
//stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
//document.body.appendChild( stats.dom );

let canvas;
let scene;
let camera;

let frontScene;
let frontCamera;

let buttonScene;
let buttonCamera;

let renderer;
let clock;

let sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

let webGLenabled = true;

let black_1;
let black_1_ShaderMaterial;

let black_2;
let black_2_ShaderMaterial;

let green_1;
let green_1_ShaderMaterial;

let green_2;
let green_2_ShaderMaterial;

let bubbleGeometry;
let blackMaterial;
let greenMaterial;

let blackBubble_1;
let blackBubble_2;
let blackBubble_3;
let blackBubble_4;
let blackBubble_5;

let greenBubble_1;
let greenBubble_2;
let greenBubble_3;
let greenBubble_4;
let greenBubble_5;

let videoTextures = [];
let videoShaderMaterial;
let videoPlane;
let videoTransitionRunning = false;
let current = 0;

const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();

let testPlane;
let testPlane2;
let testPlane3;

let gestures;

let poemBtnActive = false;
let audioBtnActive = false;

let audioOn = false;
let audioVolume = 1;
let fadeVolume = 0.4;

let audios;
let audio1loaded = false;
let audio2loaded = false;
let audio3loaded = false;

let fadingOutFromAudioButton = false;
let fadingInFromAudioButton = false;

let poems;
let poems1;
let poems2;
let poems3;
let poem1_1loaded = false;
let poem1_2loaded = false;
let poem1_3loaded = false;
let poem2_1loaded = false;
let poem2_2loaded = false;
let poem2_3loaded = false;
let poem3loaded = false;

let poemTimeout;
let poemPlaying = false;

let loadInterval;
let currentlyPlaying;
let currentPoem;

let currentSection = 0;

let button1;
let button1_ShaderMaterial;

let button2;
let button2_ShaderMaterial;

let button3;
let button3_ShaderMaterial;

let clipCounters = [0,0,0];

/*
var stats = new Stats();
stats.showPanel( 2 ); // 0: fps, 1: ms, 2: mb, 3+: custom
document.body.appendChild( stats.dom );
*/

// check signature
gsap.registerPlugin(ScrollTrigger);


let audio1 = new Howl({ src: ["audio/audio_1.mp3"], loop: true, volume: 0 });
audio1.once("load", function () {
  //console.log("audio 1 loaded");
  audio1loaded = true;
});
audio1.on('fade', function(){
  console.log("audio 1 faded, fadingout = " + fadingOutFromAudioButton);
  if(fadingOutFromAudioButton){
    pauseAfterFade();
  }
  if(fadingInFromAudioButton){
    fadingInFromAudioButton = false;
  }
});

let audio2 = new Howl({ src: ["audio/audio_2.mp3"], loop: true, volume: 0 });
audio2.once("load", function () {
  //console.log("audio 2 loaded");
  audio2loaded = true;
});
audio2.on('fade', function(){
  console.log("audio 2 faded, fadingout = " + fadingOutFromAudioButton);
  if(fadingOutFromAudioButton){
    pauseAfterFade();
  }
  if(fadingInFromAudioButton){
    fadingInFromAudioButton = false;
  }
});

let audio3 = new Howl({ src: ["audio/audio_3.mp3"], loop: true, volume: 0 });
audio3.once("load", function () {
  //console.log("audio 3 loaded");
  audio3loaded = true;
});
audio3.on('fade', function(){
  console.log("audio 3 faded, fadingout = " + fadingOutFromAudioButton);
  if(fadingOutFromAudioButton){
    pauseAfterFade();
  }
  if(fadingInFromAudioButton){
    fadingInFromAudioButton = false;
  }
});

let poem1_1 = new Howl({ src: ["audio/runo_1_1.mp3"], loop: false, volume: 1 });
poem1_1.once("load", function () {
  //console.log("poem 1_1 loaded");
  poem1_1loaded = true;
});
poem1_1.on('fade', function(){
  poemFaded();
});
poem1_1.on('end', function(){
  console.log('poem 1_1 end');
  poemFaded();
});

let poem1_2 = new Howl({ src: ["audio/runo_1_2.mp3"], loop: false, volume: 1 });
poem1_2.once("load", function () {
  console.log("poem 1_2 loaded");
  poem1_2loaded = true;
});
poem1_2.on('fade', function(){
  poemFaded();
});
poem1_2.on('end', function(){
  console.log('poem 1_2 end');
  poemFaded();
});

let poem1_3 = new Howl({ src: ["audio/runo_1_3.mp3"], loop: false, volume: 1 });
poem1_3.once("load", function () {
  //console.log("poem 1_3 loaded");
  poem1_3loaded = true;
});
poem1_3.on('fade', function(){
  poemFaded();
});
poem1_3.on('end', function(){
  console.log('poem 1_3 end');
  poemFaded();
});


let poem2_1 = new Howl({ src: ["audio/runo_2_1.mp3"], loop: false, volume: 1 });
poem2_1.once("load", function () {
  //console.log("poem 2_1 loaded");
  poem2_1loaded = true;
});
poem2_1.on('fade', function(){
  poemFaded();
});
poem2_1.on('end', function(){
  console.log('poem 2_1 end');
  poemFaded();
});

let poem2_2 = new Howl({ src: ["audio/runo_2_2.mp3"], loop: false, volume: 1 });
poem2_2.once("load", function () {
  //console.log("poem 2_2 loaded");
  poem2_2loaded = true;
});
poem2_2.on('fade', function(){
  poemFaded();
});
poem2_2.on('end', function(){
  console.log('poem 2_2 end');
  poemFaded();
});

let poem2_3 = new Howl({ src: ["audio/runo_2_3.mp3"], loop: false, volume: 1 });
poem2_3.once("load", function () {
  //console.log("poem 2_3 loaded");
  poem2_3loaded = true;
});
poem2_3.on('fade', function(){
  poemFaded();
});
poem2_3.on('end', function(){
  console.log('poem 2_3 end');
  poemFaded();
});

let poem3 = new Howl({ src: ["audio/runo_3.mp3"], loop: false, volume: 1 });
poem3.once("load", function () {
  //console.log("poem 3 loaded");
  poem3loaded = true;
});
poem3.on('fade', function(){
  poemFaded();
});
poem3.on('end', function(){
  console.log('poem 3 end');
  poemFaded();
});

audios = [audio1, audio2, audio3];
poems1 = [poem1_1,poem1_2,poem1_3];
poems2 = [poem2_1,poem2_2,poem2_3];
poems3 = [poem3];
poems = [poems1, poems2, poems3];

function playSoundBySection(id) {
  //console.log("PLAY SOUND " + id);
  if (currentlyPlaying) {
    currentlyPlaying.fade(audioVolume, 0, 3000);
  }
  let sound = audios[id];
  fadingInFromAudioButton = true;
  sound.fade(0, audioVolume, 3000);
  currentlyPlaying = sound;
}

function playAll() {
  audio1.play();
  audio2.play();
  audio3.play();
  
  playSoundBySection(currentSection);
}

function pauseAll() {
  console.log("pause all")
  /*
  audio1.pause();
  audio2.pause();
  audio3.pause();
  audio1.volume(0);
  audio2.volume(0);
  audio3.volume(0);
  */
  

  //audio1.fade(audioVolume, 0, 1500);
  //audio2.fade(audioVolume, 0, 1500);
  //audio3.fade(audioVolume, 0, 1500);
  
  currentlyPlaying.fade(audioVolume, 0, 1500);

  fadingOutFromAudioButton = true;

  currentlyPlaying = null;
}

function pauseAfterFade(){
  console.log("pause after fade")
  fadingOutFromAudioButton = false;
  audio1.pause();
  audio2.pause();
  audio3.pause();
}

window.addEventListener(
  "load",
  function () {
    checkWebGLSupportAndShowArticle();
  },
  false
);

function checkWebGLSupportAndShowArticle() {
  let canvas = document.createElement("canvas");
  let gl =
    canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
  // Report the result.
  if (gl && gl instanceof WebGLRenderingContext) {
    //console.log("webgl ok");
    webGLenabled = true;
    //hidePreloader();
    initGestures();
    initThreejs();
    startLoadCheck();
  } else {
    //console.log("webgl NOT ok");

    // show message
  }
}

function startLoadCheck() {
  loadInterval = setInterval(checkIfAssetsLoaded, 200);
}

function checkIfAssetsLoaded() {
  if (
    audio1loaded &&
    audio2loaded &&
    audio3loaded &&
    poem1_1loaded &&
    poem1_2loaded &&
    poem1_3loaded &&
    poem2_1loaded &&
    poem2_2loaded &&
    poem2_3loaded &&
    poem3loaded
  ) {
    clearInterval(loadInterval);
    hidePreloader();
  }
}

function initThreejs() {
  //console.log(gestures);

  clock = new THREE.Clock();

  canvas = document.querySelector("canvas.webgl");
  scene = new THREE.Scene();
  scene.background = new THREE.Color(0x000000);
  camera = new THREE.PerspectiveCamera(
    35,
    sizes.width / sizes.height,
    0.1,
    1000
  );
  camera.position.set(0, 0, 20);
  scene.add(camera);
  //const chelper = new THREE.CameraHelper(camera);
  //scene.add( chelper );

  frontScene = new THREE.Scene();
  frontCamera = new THREE.PerspectiveCamera(
    35,
    sizes.width / sizes.height,
    0.1,
    1000
  );
  frontCamera.position.set(0, 0, 20);
  frontScene.add(frontCamera);

  buttonScene = new THREE.Scene();
  buttonCamera = new THREE.PerspectiveCamera(
    35,
    sizes.width / sizes.height,
    0.1,
    1000
  );
  buttonCamera.position.set(0, 0, 20);
  buttonScene.add(buttonCamera);

  renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true,
  });
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.outputEncoding = THREE.LinearEncoding;
  renderer.antialias = true;
  renderer.autoClear = false;

  // video textures start
  let video1 = document.getElementById("video1");
  let videoTexture1 = new THREE.VideoTexture(video1);
  videoTexture1.needsUpdate;
  videoTexture1.minFilter = THREE.LinearFilter;
  videoTexture1.magFilter = THREE.LinearFilter;
  videoTexture1.format = THREE.RGBFormat;
  videoTexture1.crossOrigin = "anonymous";

  let video2 = document.getElementById("video2");
  let videoTexture2 = new THREE.VideoTexture(video2);
  videoTexture2.needsUpdate;
  videoTexture2.minFilter = THREE.LinearFilter;
  videoTexture2.magFilter = THREE.LinearFilter;
  videoTexture2.format = THREE.RGBFormat;
  videoTexture2.crossOrigin = "anonymous";

  let video3 = document.getElementById("video3");
  let videoTexture3 = new THREE.VideoTexture(video3);
  videoTexture3.needsUpdate;
  videoTexture3.minFilter = THREE.LinearFilter;
  videoTexture3.magFilter = THREE.LinearFilter;
  videoTexture3.format = THREE.RGBFormat;
  videoTexture3.crossOrigin = "anonymous";

  let basicMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });

  videoTextures.push(
    videoTexture1,
    basicMaterial,
    videoTexture2,
    basicMaterial,
    videoTexture3,
    basicMaterial
  );

  bubbleGeometry = new THREE.PlaneGeometry(1, 1, 32, 32);
  blackMaterial = new THREE.ShaderMaterial({
    vertexShader: elementVertex,
    fragmentShader: elementFragment,
    uniforms: {
      uTime: { value: 0.0 },
      uTexture: {
        value: new THREE.TextureLoader().load("img/tumma_kupla.png"),
      },
    },
    side: THREE.DoubleSide,
  });
  blackMaterial.transparent = true;

  greenMaterial = new THREE.ShaderMaterial({
    vertexShader: elementVertex,
    fragmentShader: elementFragment,
    uniforms: {
      uTime: { value: 0.0 },
      uTexture: {
        value: new THREE.TextureLoader().load("img/vihrea_kupla.png"),
      },
    },
    side: THREE.DoubleSide,
  });
  greenMaterial.transparent = true;

  blackBubble_1 = new THREE.Mesh(bubbleGeometry, blackMaterial);
  frontScene.add(blackBubble_1);
  blackBubble_1.position.set(-1, -6, 4);
  blackBubble_1.scale.set(1.2, 1.2, 1.2);

  blackBubble_2 = new THREE.Mesh(bubbleGeometry, blackMaterial);
  frontScene.add(blackBubble_2);
  blackBubble_2.position.set(1, -6, 4);
  blackBubble_2.scale.set(0.8, 0.8, 0.8);

  blackBubble_3 = new THREE.Mesh(bubbleGeometry, blackMaterial);
  frontScene.add(blackBubble_3);
  blackBubble_3.position.set(2, -6, 4);
  blackBubble_3.scale.set(0.5, 0.5, 0.5);

  blackBubble_4 = new THREE.Mesh(bubbleGeometry, blackMaterial);
  frontScene.add(blackBubble_4);
  blackBubble_4.position.set(3, -6, 4);
  blackBubble_4.scale.set(0.1, 0.1, 0.1);

  blackBubble_5 = new THREE.Mesh(bubbleGeometry, blackMaterial);
  frontScene.add(blackBubble_5);
  blackBubble_5.position.set(-3, -6, 4);
  blackBubble_5.scale.set(0.4, 0.4, 0.4);

  greenBubble_1 = new THREE.Mesh(bubbleGeometry, greenMaterial);
  frontScene.add(greenBubble_1);
  greenBubble_1.position.set(-1, -6, 4);
  greenBubble_1.scale.set(0.8, 0.8, 0.8);

  greenBubble_2 = new THREE.Mesh(bubbleGeometry, greenMaterial);
  frontScene.add(greenBubble_2);
  greenBubble_2.position.set(1, -6, 4);
  greenBubble_2.scale.set(1.2, 1.2, 1.2);

  greenBubble_3 = new THREE.Mesh(bubbleGeometry, greenMaterial);
  frontScene.add(greenBubble_3);
  greenBubble_3.position.set(2, -6, 4);
  greenBubble_3.scale.set(0.7, 0.7, 0.7);

  greenBubble_4 = new THREE.Mesh(bubbleGeometry, greenMaterial);
  frontScene.add(greenBubble_4);
  greenBubble_4.position.set(3, -6, 4);
  greenBubble_4.scale.set(0.4, 0.4, 0.4);

  greenBubble_5 = new THREE.Mesh(bubbleGeometry, greenMaterial);
  frontScene.add(greenBubble_5);
  greenBubble_5.position.set(-3, -6, 4);
  greenBubble_5.scale.set(0.1, 0.1, 0.1);

  /*
  let black_1_geometry = new THREE.PlaneGeometry(1, 1.75, 32, 32);
  black_1_ShaderMaterial = new THREE.ShaderMaterial({
    vertexShader: elementVertex,
    fragmentShader: elementFragment,
    uniforms: {
      uTime: { value: 0.0 },
      uTexture: {
        value: new THREE.TextureLoader().load(
          "img/transitio_musta_1_small.png"
        ),
      },
    },
    side: THREE.DoubleSide,
  });
  black_1_ShaderMaterial.transparent = true;
  black_1 = new THREE.Mesh(black_1_geometry, black_1_ShaderMaterial);
  frontScene.add(black_1);
  black_1.position.set(-2, -12, 1);
  black_1.scale.set(6, 6, 6);

 
  let black_2_geometry = new THREE.PlaneGeometry(1, 1.86, 32, 32);
  black_2_ShaderMaterial = new THREE.ShaderMaterial({
    vertexShader: elementVertex,
    fragmentShader: elementFragment,
    uniforms: {
      uTime: { value: 0.0 },
      uTexture: {
        value: new THREE.TextureLoader().load(
          "img/transitio_musta_2_small.png"
        ),
      },
    },
    side: THREE.DoubleSide,
  });
  black_2_ShaderMaterial.transparent = true;
  black_2 = new THREE.Mesh(black_2_geometry, black_2_ShaderMaterial);
  frontScene.add(black_2);
  black_2.position.set(2, -12, 2.5);
  black_2.scale.set(6, 6, 6);

  
  let green_1_geometry = new THREE.PlaneGeometry(1, 0.92, 32, 32);
 green_1_ShaderMaterial = new THREE.ShaderMaterial({
    vertexShader: elementVertex,
    fragmentShader: elementFragment2,
    uniforms: {
      uTime: { value: 0.0 },
      uTexture: {
        value: new THREE.TextureLoader().load(
          "img/transitio_vihr_1_small.png"
        ),
      },
    },
    side: THREE.DoubleSide,
  });
  green_1_ShaderMaterial.transparent = true;
  green_1 = new THREE.Mesh(green_1_geometry, green_1_ShaderMaterial);
  frontScene.add(green_1);
  green_1.position.set(-2, -10, 2);
  green_1.scale.set(7, 7, 7);


  let green_2_geometry = new THREE.PlaneGeometry(1, 1.55, 32, 32);
 green_2_ShaderMaterial = new THREE.ShaderMaterial({
    vertexShader: elementVertex,
    fragmentShader: elementFragment2,
    uniforms: {
      uTime: { value: 0.0 },
      uTexture: {
        value: new THREE.TextureLoader().load(
          "img/transitio_vihr_2_small.png"
        ),
      },
    },
    side: THREE.DoubleSide,
  });
  green_2_ShaderMaterial.transparent = true;
  green_2 = new THREE.Mesh(green_2_geometry, green_2_ShaderMaterial);
  frontScene.add(green_2);
  green_2.position.set(2, -10, 4);
  green_2.scale.set(5,5,5); */

  videoShaderMaterial = new THREE.ShaderMaterial({
    extensions: {
      derivatives: "#extension GL_OES_standard_derivatives : enable",
    },
    side: THREE.DoubleSide,
    uniforms: {
      time: { type: "f", value: 0 },
      progress: { type: "f", value: 0 },
      border: { type: "f", value: 0 },
      intensity: { type: "f", value: 0.3 },
      scaleX: { type: "f", value: 40 },
      scaleY: { type: "f", value: 40 },
      transition: { type: "f", value: 40 },
      swipe: { type: "f", value: 0 },
      width: { type: "f", value: 0.5 },
      radius: { type: "f", value: 0 },
      texture1: { type: "f", value: videoTextures[0] },
      texture2: { type: "f", value: videoTextures[1] },
      resolution: { type: "v4", value: new THREE.Vector4() },
      dir: { type: "v2", value: new THREE.Vector2(0, -1) },
    },
    vertexShader: videoVertex,
    fragmentShader: videoFragment,
  });

  let videoGeo = new THREE.PlaneGeometry(1, 1, 2, 2);
  videoPlane = new THREE.Mesh(videoGeo, videoShaderMaterial);
  scene.add(videoPlane);

  let testGeo = new THREE.PlaneGeometry(1, 1, 2, 2);

  const testMaterial = new THREE.MeshBasicMaterial({
    color: 0xff0000,
  });
  testPlane = new THREE.Mesh(testGeo, testMaterial);
  //testPlane.name = "nappi";
  //buttonScene.add(testPlane);

  const testMaterial2 = new THREE.MeshBasicMaterial({
    color: 0x00ff00,
  });
  testPlane2 = new THREE.Mesh(testGeo, testMaterial2);
  //testPlane2.name = "nappi 2";
  //buttonScene.add(testPlane2);

  const testMaterial3 = new THREE.MeshBasicMaterial({
    color: 0x0000ff,
  });
  testPlane3 = new THREE.Mesh(testGeo, testMaterial3);
  
  //scene.add(testPlane3);

  
let button1Geometry = new THREE.PlaneGeometry(1, 0.98, 32, 32);
 button1_ShaderMaterial = new THREE.ShaderMaterial({
    vertexShader: buttonVertex,
    fragmentShader: buttonFragment,
    uniforms: {
      uFreq: {value:0.0},
      uAmp: {value:0.12},
      uOpacity: {value:0.4},
      uTime: { value: 0.0 },
      uTexture: {
        value: new THREE.TextureLoader().load(
          "img/symbol-2.png"
        ),
      },
    },
    side: THREE.DoubleSide,
  });

  button1_ShaderMaterial.transparent = true;
  button1 = new THREE.Mesh(button1Geometry, button1_ShaderMaterial);
  button1.name = "nappi 3";
  button1.position.set(0, 0, 5);
  buttonScene.add(button1);


  let button2Geometry = new THREE.PlaneGeometry(1, 1.25, 32, 32);
 button2_ShaderMaterial = new THREE.ShaderMaterial({
    vertexShader: buttonVertex,
    fragmentShader: buttonFragment,
    uniforms: {
      uFreq: {value:0.0},
      uAmp: {value:0.12},
      uOpacity: {value:0.4},
      uTime: { value: 0.0 },
      uTexture: {
        value: new THREE.TextureLoader().load(
          "img/symbol-1.png"
        ),
      },
    },
    side: THREE.DoubleSide,
  });

  button2_ShaderMaterial.transparent = true;
  button2 = new THREE.Mesh(button2Geometry, button2_ShaderMaterial);
  button2.name = "nappi 2";
  button2.position.set(0, 0, 5);
  buttonScene.add(button2);


  let button3Geometry = new THREE.PlaneGeometry(1, 1.17, 32, 32);
  button3_ShaderMaterial = new THREE.ShaderMaterial({
     vertexShader: buttonVertex,
     fragmentShader: buttonFragment,
     uniforms: {
       uFreq: {value:0.0},
       uAmp: {value:0.12},
       uOpacity: {value:0.4},
       uTime: { value: 0.0 },
       uTexture: {
         value: new THREE.TextureLoader().load(
           "img/symbol-4.png"
         ),
       },
     },
     side: THREE.DoubleSide,
   });
 
   button3_ShaderMaterial.transparent = true;
   button3 = new THREE.Mesh(button3Geometry, button3_ShaderMaterial);
   button3.name = "nappi";
   button3.position.set(0, 0, 5);
   buttonScene.add(button3);


  /*
  let gui = new dat.GUI();
  const shaderFolder1 = gui.addFolder("Shader freq")
  shaderFolder1.add(button1_ShaderMaterial.uniforms.uFreq, "value", 0.1, 10.0)
  const shaderFolder2 = gui.addFolder("Shader amp")
  shaderFolder2.add(button1_ShaderMaterial.uniforms.uAmp, "value", 0.1, 10.0)
  const shaderFolder3 = gui.addFolder("Shader opa")
  shaderFolder3.add(button1_ShaderMaterial.uniforms.uOpacity, "value", 0, 1)
*/

  resize();
  tick();
  //hidePreloader();
}

let time;
const tick = () => {
  //controls.update();

  //blackMaterial.uniforms.uTime.value = clock.getElapsedTime();
  //greenMaterial.uniforms.uTime.value = clock.getElapsedTime();
  

  //stats.begin();

  time = clock.getElapsedTime();
  //console.log(time)

  
  //button1_ShaderMaterial.uniforms.uTime.value = clock.getElapsedTime();
  button1_ShaderMaterial.uniforms.uTime.value = time.toFixed(2);
  button2_ShaderMaterial.uniforms.uTime.value = time.toFixed(2);
  button3_ShaderMaterial.uniforms.uTime.value = time.toFixed(2);
  /* black_1_ShaderMaterial.uniforms.uTime.value = clock.getElapsedTime();
  black_2_ShaderMaterial.uniforms.uTime.value = clock.getElapsedTime();
  green_1_ShaderMaterial.uniforms.uTime.value = clock.getElapsedTime();
  green_2_ShaderMaterial.uniforms.uTime.value = clock.getElapsedTime(); */

  
 

 
    //stats.end();

  renderer.clear();

  window.requestAnimationFrame(tick);
  renderer.render(scene, camera);
  renderer.render(frontScene, frontCamera);
  renderer.render(buttonScene, buttonCamera);
  

  //stats.begin();
  //stats.end();
};

const visibleHeightAtZDepth = (depth, camera) => {
  // compensate for cameras not positioned at z=0
  const cameraOffset = camera.position.z;
  if (depth < cameraOffset) depth -= cameraOffset;
  else depth += cameraOffset;
  // vertical fov in radians
  const vFOV = (camera.fov * Math.PI) / 180;
  // Math.abs to ensure the result is always positive
  return 2 * Math.tan(vFOV / 2) * Math.abs(depth);
};

const visibleWidthAtZDepth = (depth, camera) => {
  const height = visibleHeightAtZDepth(depth, camera);
  return height * camera.aspect;
};

function initGestures() {

  $('body').on('mousewheel DOMMouseScroll', function(e){
    if(typeof e.originalEvent.detail == 'number' && e.originalEvent.detail !== 0) {
      if(e.originalEvent.detail > 0 && current != 4 && !videoTransitionRunning) {
        //console.log('Down');
        swapVideo("down");
      } else if(e.originalEvent.detail < 0 && current != 0 && !videoTransitionRunning){
          //console.log('Up');
          swapVideo("up");
      }
    } else if (typeof e.originalEvent.wheelDelta == 'number') {
      if(e.originalEvent.wheelDelta < 0 && current != 4 && !videoTransitionRunning) {
          //console.log('Down');
          swapVideo("down");
      } else if(e.originalEvent.wheelDelta > 0 && current != 0 && !videoTransitionRunning) {
          //console.log('Up');
          swapVideo("up");
      }
    }
  });



  let el = document.querySelector(".ui-container");

  gestures = new Hammer(el);
  gestures.get("swipe").set({ direction: Hammer.DIRECTION_VERTICAL });
  gestures.on("tap swipeup swipedown", function (ev) {
    let xpos = ev.center.x;
    let ypos = ev.center.y;
    let posInWindow = Math.floor((ev.center.y * 100) / window.innerHeight);

    mouse.x = (ev.center.x / window.innerWidth) * 2 - 1;
    mouse.y = -(ev.center.y / window.innerHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, buttonCamera);

    //console.log(xpos + "/" + ypos)
    //let txt = ev.type + " gesture detected.";
    //console.log(txt)

    if (ev.type == "swipedown" && current != 0 && !videoTransitionRunning) {
      //console.log("swipe down")
      swapVideo("up");
    } else if (
      ev.type == "swipeup" &&
      current != 4 &&
      !videoTransitionRunning
    ) {
      //console.log("swipe up")
      swapVideo("down");
    } else if (ev.type == "tap") {
      let btnClick = false;
      //console.log("tap")
      const intersects = raycaster.intersectObjects(buttonScene.children);

      for (let i = 0; i < intersects.length; i++) {
        //console.log(intersects[ i ].object.name);
        let btnName = intersects[i].object.name;
        if (btnName == "nappi") {
          //console.log("nappi clicked");
          showInfoOverlay();
          btnClick = true;
        } else if (btnName == "nappi 2") {
          //console.log("nappi 2 clicked")
          audioBtnClicked();
          btnClick = true;
        } else if (btnName == "nappi 3") {
          //console.log("nappi 3 clicked")
          poemBtnClicked();
          btnClick = true;
        }
      }

      if (btnClick == false) {
        if (posInWindow > 50) {
          if (current != 4 && !videoTransitionRunning) {
            swapVideo("down");
          }
        } else {
          if (current != 0 && !videoTransitionRunning) {
            swapVideo("up");
          }
        }
      }
    }
  });

  $(".info-close").on("click", (e) => {
    hideInfoOverlay();
  });
}

/*
function startPoemTimeout() {
  let time;
  if (currentSection == 0) {
    time = 88000;
  } else if (currentSection == 1) {
    time = 87000;
  } else {
    time = 47000;
  }
  poemTimeout = setTimeout(stopPoemTimeout, time);
}

function stopPoemTimeout() {
 
  deactivatePoemBtn();
  clearTimeout(poemTimeout);
  poemPlaying = false;
  
  if (currentlyPlaying) {
    audioVolume = 1;
    currentlyPlaying.fade(0.3, 1, 200);
  }
}
*/


function fadePoemOut(){
  //console.log("fade poem")
  currentPoem.fade(1, 0, 1000);
  poemPlaying = false;
}

function poemFaded(){
  //console.log("poem faded")
  

  deactivatePoemBtn();
  //stopPoemTimeout();
  currentPoem.stop();
  if (currentlyPlaying) {
    console.log("after poem, fade in audio");
    audioVolume = 1;
    currentlyPlaying.fade(0.3, 1, 1500);
  } else {
    console.log("after poem, no audio playing");
  }
}

function updateCounter(){
  let counter = clipCounters[currentSection];
  let numOfPoems = poems[currentSection].length;
  //console.log("counter : " + counter);
  //console.log("poems length :" + numOfPoems);
  counter++;
  if(counter >= numOfPoems){
    counter = 0;
  }
  clipCounters[currentSection] = counter;
  //console.log(clipCounters);
}

function poemBtnClicked() {
  //console.log("poem btn clicked")
  poemPlaying = !poemPlaying;

  //console.log("toggled to " + poemPlaying);

  let counter = clipCounters[currentSection];

  //console.log(counter)

  if (poemPlaying) {
    activatePoemBtn();
    currentPoem = poems[currentSection][counter];
    //startPoemTimeout();
    currentPoem.volume(1);
    currentPoem.play();
    if (currentlyPlaying) {
      audioVolume = 0.3;
      currentlyPlaying.fade(1, 0.3, 500);
    }
  } else {
    deactivatePoemBtn();
    //stopPoemTimeout();
    //currentPoem.stop();
    fadePoemOut()
    if (currentlyPlaying) {
      audioVolume = 1;
      currentlyPlaying.fade(0.3, 1, 500);
    }
  }
}

function activatePoemBtn(){
  //testPlane3.material.color.setHex( 0xffffff );
  gsap.to(button1_ShaderMaterial.uniforms.uFreq,{ duration:1, value:0.0 });
  gsap.to(button1_ShaderMaterial.uniforms.uAmp,{ duration:1, value:0.25 });
  gsap.to(button1_ShaderMaterial.uniforms.uOpacity,{ duration:1, value:0.8 });
}

function deactivatePoemBtn(){
  //testPlane3.material.color.setHex( 0x0000ff );
  updateCounter();
  gsap.to(button1_ShaderMaterial.uniforms.uFreq,{ duration:1, value: 0.0 });
  gsap.to(button1_ShaderMaterial.uniforms.uAmp,{ duration:1, value:0.12 });
  gsap.to(button1_ShaderMaterial.uniforms.uOpacity,{ duration:1, value:0.4 });
}

function audioBtnClicked() {

  console.log("audioBtnClicked, fadingOutFromAudioButton:  " + fadingOutFromAudioButton)

  if(fadingOutFromAudioButton == false && fadingInFromAudioButton == false){
      audioOn = !audioOn;
    if (audioOn) {
      activateAudioBtn();
      playAll();
    } else {
      deactivateAudioBtn();
      pauseAll();
    }
  }
}

function activateAudioBtn(){
  //testPlane2.material.color.setHex( 0xffffff );
  gsap.to(button2_ShaderMaterial.uniforms.uFreq,{ duration:1, value:0.0 });
  gsap.to(button2_ShaderMaterial.uniforms.uAmp,{ duration:1, value:0.25 });
  gsap.to(button2_ShaderMaterial.uniforms.uOpacity,{ duration:1, value:0.8 });
}

function deactivateAudioBtn(){
  //testPlane2.material.color.setHex( 0x00ff00 );
  gsap.to(button2_ShaderMaterial.uniforms.uFreq,{ duration:1, value: 0.0 });
  gsap.to(button2_ShaderMaterial.uniforms.uAmp,{ duration:1, value:0.12 });
  gsap.to(button2_ShaderMaterial.uniforms.uOpacity,{ duration:1, value:0.4 });
}

function blackBubbelsUp() {
  gsap.fromTo(
    blackBubble_1.position,
    { y: -6 },
    { y: 6, duration: 3, delay: 2 }
  );
  gsap.fromTo(
    blackBubble_2.position,
    { y: -6 },
    { y: 6, duration: 2.6, delay: 2.2 }
  );
  gsap.fromTo(
    blackBubble_3.position,
    { y: -6 },
    { y: 6, duration: 2, delay: 2.4 }
  );
  gsap.fromTo(
    blackBubble_4.position,
    { y: -6 },
    { y: 6, duration: 3, delay: 2.5 }
  );
  gsap.fromTo(
    blackBubble_5.position,
    { y: -6 },
    { y: 6, duration: 2, delay: 2.7 }
  );
}

function greenBubbelsUp() {
  gsap.fromTo(
    greenBubble_1.position,
    { y: -6 },
    { y: 6, duration: 3, delay: 2 }
  );
  gsap.fromTo(
    greenBubble_2.position,
    { y: -6 },
    { y: 6, duration: 2.6, delay: 2.2 }
  );
  gsap.fromTo(
    greenBubble_3.position,
    { y: -6 },
    { y: 6, duration: 2, delay: 2.4 }
  );
  gsap.fromTo(
    greenBubble_4.position,
    { y: -6 },
    { y: 6, duration: 3, delay: 2.5 }
  );
  gsap.fromTo(
    greenBubble_5.position,
    { y: -6 },
    { y: 6, duration: 2, delay: 2.7 }
  );
}

function swapVideo(direction) {
  if($(".welcome").css("opacity") != 0){
    gsap.to(".welcome", {duration: 1,autoAlpha: 0,ease: Expo.easeInOut});
  }
  
  if (videoTransitionRunning) return;
  videoTransitionRunning = true;
  let len = videoTextures.length;
  let nextTexture;
  if (direction == "up") {
    nextTexture = videoTextures[(current - 1) % len];
  } else {
    nextTexture = videoTextures[(current + 1) % len];
  }

  let indicator = $(".depth-indicator");
  let indicatorHeight = $(".depth").height();


  //console.log("swap video, poem on: " + poemPlaying)

  //console.log(current);
  if (current == 0 && direction == "down") {
    if(poemPlaying) fadePoemOut();
    greenBubbelsUp();
    playSoundBySection(1);
    currentSection=1;
    videoShaderMaterial.uniforms.dir.value = new THREE.Vector2(0, 1);
    gsap.to(indicator, { y: indicatorHeight / 2, duration: 3, delay: 0.5 });
  } else if (current == 2 && direction == "down") {
    if(poemPlaying) fadePoemOut();
    blackBubbelsUp();
    playSoundBySection(2);
    currentSection=2;
    videoShaderMaterial.uniforms.dir.value = new THREE.Vector2(0, 1);
    gsap.to(indicator, { y: indicatorHeight, duration: 3, delay: 0.5 });
  } else if (current == 4 && direction == "up") {
    if(poemPlaying) fadePoemOut();
    playSoundBySection(1);
    currentSection=1;
    videoShaderMaterial.uniforms.dir.value = new THREE.Vector2(0, -1);
    gsap.to(indicator, { y: indicatorHeight / 2, duration: 3, delay: 0.5 });
  } else if (current == 2 && direction == "up") {
    if(poemPlaying) fadePoemOut();
    playSoundBySection(0);
    currentSection=0;
    videoShaderMaterial.uniforms.dir.value = new THREE.Vector2(0, -1);
    gsap.to(indicator, { y: 0, duration: 3, delay: 0.5 });
  }

  //nextTexture = videoTextures[(current +1)%len];
  videoShaderMaterial.uniforms.texture2.value = nextTexture;

  let tl = gsap.timeline();
  tl.to(videoShaderMaterial.uniforms.progress, {
    duration: 2,
    value: 1,
    ease: Power2.easeInOut,
    onComplete: () => {
      //console.log("video 1 transition done");
      //current = (current +1)%len;
      videoShaderMaterial.uniforms.texture1.value = nextTexture;
      videoShaderMaterial.uniforms.progress.value = 0;
      //nextTexture = videoTextures[(current +1)%len];
      if (direction == "up") {
        current = (current - 1) % len;
        nextTexture = videoTextures[(current - 1) % len];
      } else {
        current = (current + 1) % len;
        nextTexture = videoTextures[(current + 1) % len];
      }
      videoShaderMaterial.uniforms.texture2.value = nextTexture;
    },
  });

  tl.to(videoShaderMaterial.uniforms.progress, {
    duration: 2,
    value: 1,
    ease: Power2.easeInOut,
    onComplete: () => {
      //console.log("video 2 transition done");
      //current = (current +1)%len;
      if (direction == "up") {
        current = (current - 1) % len;
      } else {
        current = (current + 1) % len;
      }
      //console.log(current);
      videoShaderMaterial.uniforms.texture1.value = nextTexture;
      videoShaderMaterial.uniforms.progress.value = 0;
      videoTransitionRunning = false;
    },
  });
}

function swapToVideo(num) {
  if (videoTransitionRunning) return;
  videoTransitionRunning = true;
  let nextTexture = videoTextures[num];
  videoShaderMaterial.uniforms.texture2.value = nextTexture;
  let tl = gsap.timeline();
  tl.to(videoShaderMaterial.uniforms.progress, {
    duration: 3,
    value: 1,
    ease: Power2.easeInOut,
    onComplete: () => {
      videoShaderMaterial.uniforms.texture1.value = nextTexture;
      videoShaderMaterial.uniforms.progress.value = 0;
      videoTransitionRunning = false;
    },
  });
}

function hidePreloader() {
  gsap.to(".preload-ani", {
    duration: 0.5,
    scaleX: 0,
    scaleY: 0,
    autoAlpha: 0,
    transformOrigin: "50% 50%",
  });

  gsap.to(".preload-overlay", {
    duration: 1,
    autoAlpha: 0,
    ease: Expo.easeInOut,
    delay: 0.3,
  });

  $(".depth-indicator").css("opacity", 1);
  $(".welcome").css("opacity", 1);
}

window.addEventListener("resize", () => {
  resize();
});

function resize() {
  //console.log("resize");
  let width = window.innerWidth;
  let height = window.innerHeight;

  if(width > 800){
    $(".welcome").width(800)
  } else {
    $(".welcome").width(width*0.9)
  }

  if (webGLenabled) {
    camera.aspect = width / height;
    frontCamera.aspect = width / height;
    buttonCamera.aspect = width / height;
    renderer.setSize(width, height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    // image cover
    //this.imageAspect = this.textures[0].image.height/this.textures[0].image.width;
    let imageAspect = 788 / 1440;
    let a1;
    let a2;
    if (height / width > imageAspect) {
      a1 = (width / height) * imageAspect;
      a2 = 1;
    } else {
      a1 = 1;
      a2 = height / width / imageAspect;
    }

    videoShaderMaterial.uniforms.resolution.value.x = width;
    videoShaderMaterial.uniforms.resolution.value.y = height;
    videoShaderMaterial.uniforms.resolution.value.z = a1;
    videoShaderMaterial.uniforms.resolution.value.w = a2;

    const dist = camera.position.z;
    const h = 1;
    camera.fov = 2 * (180 / Math.PI) * Math.atan(h / (2 * dist));

    videoPlane.scale.x = camera.aspect;
    videoPlane.scale.y = 1;


    let maxW = visibleWidthAtZDepth(5, buttonCamera);
    let maxH = visibleHeightAtZDepth(5, buttonCamera);
    //console.log("w" + maxW);
    //console.log("h" + maxH);
   

    //testPlane.position.x = maxW / 2 - 0.5;
    //testPlane.position.y = maxH / 2 - 0.5;

    //testPlane2.position.x = maxW / 2 - 0.5;
    //testPlane2.position.y = maxH / 2 * -1 + 0.5;

    button3.position.x = (maxW/2) - 0.7;
    button3.position.y = 4;

    button2.position.x = (maxW/2) - 0.7;
    button2.position.y = -2;

    button1.position.x = (maxW/2) - 0.7;
    button1.position.y = -4;

    camera.updateProjectionMatrix();
    frontCamera.updateProjectionMatrix();
    buttonCamera.updateProjectionMatrix();
  }

  $(".three-container").width(width);
  $(".three-container").height(height);
  $(".preload-overlay").width(width);
  $(".preload-overlay").height(height);
  $(".ui-container").width(width);
  $(".ui-container").height(height);
  $(".info-overlay").width(width);
  $(".info-overlay").height(height);
  $(".info-content").height(height);
  $(".depth").height(height * 0.9);
}

function showInfoOverlay() {
  gsap.to(".info-overlay", {
    duration: 1,
    autoAlpha: 1,
  });
}

function hideInfoOverlay() {
  gsap.to(".info-overlay", {
    duration: 1,
    autoAlpha: 0,
  });
}
